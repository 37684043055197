<!--
 * @Description: 品牌库
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-21 22:05:31
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/brandLibrary/index.vue
-->
<template>
  <div class="app-wrapper source-material">
    <div class="content-title">素材上传</div>
    <div class="content-head">
      <div class="head-btn">
        <div class="btn" v-if="activeIndex === 0" @click="popupState = true">
          上传负面词
        </div>
        <div
          class="btn"
          v-else-if="activeIndex === 1"
          @click="popupState = true"
        >
          上传核心词
        </div>
        <div class="btn" v-if="activeIndex === 1" @click="handleSubmit">
          提交审核
        </div>      
      </div>
      <div class="head-tabs">
        <div
          :class="['tab-item', { active: activeIndex === 1 }]"
          @click="activeIndex = 1"
        >
          核心词
        </div>
        <div
          :class="['tab-item', { active: activeIndex === 0 }]"
          @click="activeIndex = 0"
        >
          负面词
        </div>
        <div
          :class="['tab-item', { active: activeIndex === 2 }]"
          @click="activeIndex = 2"
        >
          高频词
        </div>        
      </div>
    </div>
    <!-- 负面词 -->
    <negative-words-view
      ref="negative"
      v-if="activeIndex === 0"
    ></negative-words-view>
    <negative-add-popup
      v-if="activeIndex === 0 && popupState"
      :show.sync="popupState"
      @update="getRefresh"
    ></negative-add-popup>

    <!-- 核心词 -->
    <core-words-view
      ref="coreWords"
      v-else-if="activeIndex === 1"
    ></core-words-view>
    <core-add-popup
      v-if="activeIndex === 1 && popupState"
      :show.sync="popupState"
      @update="getRefresh"
    ></core-add-popup>

    <!-- 高频词 -->
    <frequence-words-view
      ref="coreWords"
      v-else-if="activeIndex === 2"
    ></frequence-words-view>
  </div>
</template>

<script>
import NegativeWordsView from "../sourceMaterial/mods/negativeWords.vue";
import NegativeAddPopup from "../sourceMaterial/components/negativeAdd.vue";

import CoreWordsView from "../sourceMaterial/mods/coreWords.vue";
import CoreAddPopup from "../sourceMaterial/components/coreAdd.vue";
import FrequenceWordsView from "../frequenceKwd/index.vue";

import { HTTP } from "../../../../utils/request";

const URL = {
  audit: "api/customer/userMaterial/audit",
};

export default {
  components: {
    NegativeWordsView,
    NegativeAddPopup,

    CoreWordsView,
    CoreAddPopup,

    FrequenceWordsView
  },
  data() {
    return {
      activeIndex: 1,
      popupState: false,
    };
  },
  methods: {
    getRefresh() {
      const ref =
        this.$refs?.negative ||
        this.$refs?.coreWords ||
        this.$refs?.images ||
        this.$refs?.videos;
      ref?.getList(1);
    },
    // 提交
    async handleSubmit() {
      await HTTP({
        url: URL.audit,
        method: "post",
        data: {
          materialType: this.activeIndex + 1,
        },
      });
      this.$message.success("操作成功");
      this.getRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.source-material {
  padding: 16px 26px;
  .content-title {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
    font-weight: normal;
    color: #2f3356;
    line-height: 29px;
  }
  .content-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .head-btn {
      display: flex;
      align-items: center;
      .btn {
        width: 128px;
        height: 33px;
        background: #4e6bef;
        margin-right: 12px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .head-tabs {
      display: flex;
      align-items: center;
      .tab-item {
        width: 76px;
        height: 33px;
        background: #f6f9ff;
        border: 1px solid #bec3cb;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #353535;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 12px;
        position: relative;
      }
      .active {
        border: 1px solid #4586f0;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          display: inline-block;
          background: url("../../../../assets/icon/active.png");
          background-size: cover;
          right: 0px;
          bottom: 0px;
        }
      }
    }
  }
}
</style>
