<!--
 * @Description: 素材管理
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-18 19:22:34
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/sourceMaterial/index.vue
-->
<template>
  <div class="app-wrapper source-material">
    <div class="content-head">
      <div class="head-btn">
        <div class="btn" v-if="keywordType !== 1" @click="showCoreWords">
          返回
        </div>        
      </div>
    </div>
    <!-- 核心词 -->
    <core-words-view
      ref="coreWords"
      v-if="keywordType === 1"
    ></core-words-view>
    <frequent-words-view
      ref="frequentWords"
      :seedKwd=this.seedKwd
      v-if="keywordType === 2"
    ></frequent-words-view>
  </div>
</template>

<script>

import CoreWordsView from "./mods/coreWords.vue";
import FrequentWordsView from "./mods/frequentWords.vue";
import { HTTP } from "../../../../utils/request";

const URL = {
  audit: "api/customer/userMaterial/createAuditTask",
};

export default {
  components: {
    CoreWordsView,
    FrequentWordsView,
  },
  data() {
    return {
      keywordType: 1,
      seedKwd: '',
    };
  },
  methods: {
    getRefresh() {
      const ref =
        this.$refs?.frequentWords ||
        this.$refs?.coreWords;
      ref?.getList(1);
    },
    // 提交
    async handleSubmit() {
      await HTTP({
        url: URL.audit,
        method: "post",
        data: {
          materialType: 5,
        },
      });
      this.$message.success("操作成功");
      this.getRefresh();
    },
    showCoreWords(){
      this.keywordType = 1;
    },
    showFrequentKwdId(seedKwd){
      this.seedKwd = seedKwd;
      this.keywordType = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.source-material {
  padding: 16px 26px;
  .content-title {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
    font-weight: normal;
    color: #2f3356;
    line-height: 29px;
  }
  .content-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .head-btn {
      display: flex;
      align-items: center;
      .btn {
        width: 128px;
        height: 33px;
        background: #4e6bef;
        margin-right: 12px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .head-tabs {
      display: flex;
      align-items: center;
      .tab-item {
        width: 76px;
        height: 33px;
        background: #f6f9ff;
        border: 1px solid #bec3cb;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #353535;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 12px;
        position: relative;
      }
      .active {
        border: 1px solid #4586f0;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          display: inline-block;
          background: url("../../../../assets/icon/active.png");
          background-size: cover;
          right: 0px;
          bottom: 0px;
        }
      }
    }
  }
}
</style>
